.flow-publish-top{
    padding-top: 1.5rem;
      .flowy-text{
        font-weight: bold;
        padding-left: 0.25rem;
        padding-top: 0.75rem;
        color: #000000;
      }
      .back-arrow{
        :hover{
            cursor: pointer;
        }
        color: #000000;
      }

      border-bottom: 1.5px solid #FAFAFA;

      .link-text{
        font-weight: bold;
      }      
   
}

.publish-card{
    .helpdesk-card{
        padding-left: 2rem;
        padding-right: 2rem;

        .helpdesk-img{
            max-height: 12rem;
        }

        .helpdesk-text{
            text-align: justify;
        }

        .connect-btn{
            margin-top: 2.5rem;
            text-align: end;
        }
        .white-text-button{
            color: white;
          }
    }

}

.helpdesk-info{
    background-color: #fff1e0 !important;
    padding: 1rem;
    border-radius: 25px;

    .info-icon{
        color: #CD6E1B !important;
        margin-top: 0.75rem;
        margin-left: 1rem;
    }

    .helpdesk-btn{
        margin-top: 1rem;
        margin-right: 1.5rem;
        text-align: end;
    }

    .white-text-button{
        color: white;
      }
}