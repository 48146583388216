// Import the Bootstrap variables
@import "~bootstrap/scss/bootstrap";

// Define your custom primary color
$primary: #00aeff; // Replace with your desired primary color
$danger: #dc3545;

// Override Bootstrap variables with your custom values
$theme-colors: (
  "primary": $primary,
  "danger":$danger
  // Add other Bootstrap colors as needed
);

// Import Bootstrap to generate the custom theme
@import "~bootstrap/scss/bootstrap";

