.tool-kit-container{
    .tool-sections{
        margin-bottom: 1.5rem;
    }
    .info-icon{
        color: #004b78 ;
    }
    
}

.tool-kit-component{
    :hover{
        cursor: pointer;
    }  
    .tool-kit-content{
        justify-content: center;
    }
    .bubble-item{
        color: #5682e5
    }
    .input-item{
        color: green;
    }
}

.tooltip-text {
    background-color: #000000;
    //background-color: #333;
    color: #fff;
    text-align: justify;
    padding: 1.5rem;
    border-radius: 0.5rem;
    width: 25rem;
    z-index: 1;
  }

.card{
    border-color: rgba($color: #004b78, $alpha: 0.2) !important;
    box-shadow: rgba(0, 75, 120, 0.19) 0px 10px 20px, rgba(0, 75, 120, 0.23) 0px 3px 3px;
    // box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 3px 3px;
}




