@import '../../../LocalTheme.scss';

.loading-component{
    min-height: 120px;
    width: 100%;
    position: relative;
}

.loading-component .loader-content{
    text-align: center;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    fill: $primary;
}