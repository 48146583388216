@import '../../LocalTheme.scss';

.flow-editor-top{
    padding-top: 8.5rem;
      .flowy-text{
        font-weight: bold;
        padding-left: 0.25rem;
        padding-top: 0.75rem;
        color: #000000;
      }
      .back-arrow{
        :hover{
            cursor: pointer;
        }
        color: #000000;
      }

      .top-buttons{
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }

      .save{
        padding-right: 1rem;
      }

      .white-text-button{
        color: white;
      }
}
.flow-editor-bottom{
    padding-top: 8.5rem;
   
    .menu-icon{
        position: absolute;
        bottom: 1rem;
        right: 0.5rem;
    }
    .edit{
        margin-right: .75rem;
        :hover{
            cursor: pointer;
        }
    }
    .delete{
        :hover{
            cursor: pointer;
        }
    }
}

.top-layout-editor{
  height: 7.5rem;
  border-bottom: solid 1px rgba(112, 112, 112, 0.08);
  background-color: #f4f4f4 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  .flow-list-view-top{
      padding-top: 0.75rem;
      padding-bottom: 1rem;

      .control-hub{
        cursor: pointer;
      }

      .control-hub-navigator{
          margin-right: 0.75rem;
          margin-top: 1.25rem;
      }
  
      .logo-container {
          height: 1.8rem;
          img {
            height: 100%;
            max-height: 100%;
            max-width: 100%;
          }
        }

        .profile-icon{
          color: $primary !important;
        }

        .logout-text{
          margin-left: 0.5rem;
        }

        .dropdown-btn {
          padding: 0rem !important;
          font-size: 0.875rem;
          border: none;
          background-color: inherit;
        }
  
      .single-dropdown-toggle::after {
          content: none;
        }

        .flowy-text{
          font-weight: bold;
          padding-top: 0.5rem;
        }
        .flowy-sub-text{
          font-weight: bold;
          padding-bottom: 0.5rem;
        }
        .flex-start { 
          justify-content: flex-start; 
        }
        .flex-end { 
          justify-content: flex-end; 
        }
        .control-hub-text{
          margin-right: 0.75rem;
        }
  }
}


$border-color_1: #0041d0;
$border-color_2: #ff0072;

.dndflow {
	flex-direction: column;
	display: flex;
	flex-grow: 1;
	height: 100%;
	aside {
		border-right: 1px solid #eee;
		padding: 15px 10px;
		font-size: 12px;
		background: #fcfcfc;
		.description {
			margin-bottom: 10px;
		}
	}
	.dndnode {
		height: 20px;
		padding: 4px;
		border: 1px solid #1a192b;
		border-radius: 2px;
		margin-bottom: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: grab;
	}
	.dndnode.input {
		border-color: $border-color_1;
	}
	.dndnode.output {
		border-color: $border-color_2;
	}
	.reactflow-wrapper {
		flex-grow: 1;
		height: 100%;
	}
	.selectall {
		margin-top: 10px;
	}
}
@media screen and (min-width: 768px) {
	.dndflow {
		flex-direction: row;
		aside {
			width: 20%;
			max-width: 250px;
		}
	}
}
