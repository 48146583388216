
.flow-list-view-bottom{
    padding-top: 2rem;

    .adding-card{
        text-align: center;
        background-color: #004b78;
        height: 20rem !important;
        color: azure;
        :hover{
            cursor: pointer;
        }
    }
    .list-card{
        text-align: center;
        color: #004b78;
        height: 20rem !important;
    }

    .vertical-center{
        margin: 0;
        position: absolute;
        top: 30%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        :hover{
            cursor: pointer;
        }
    }
    .horizontal-center{
        margin: 0;
        position: absolute;
        left: 40%;
        -ms-transform: translateX(-35%);
        transform: translateX(-35%);
    }

    .card-icon{
        padding-bottom: 1.25rem;
    }
    .menu-icon{
        position: absolute;
        bottom: 1rem;
        right: 0.5rem;
    }
    .edit{
        margin-right: .5rem;
        :hover{
            cursor: pointer;
        }
    }
    .delete{
        :hover{
            cursor: pointer;
        }
    }
}