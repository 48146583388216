.button-input-node{
    z-index: -1;

    // .btn-outline-primary{
    //     border-color: #004b78 !important;
    //     --bs-btn-color: #004b78 !important;
    //     --bs-btn-hover-bg: #004b78 !important;
    //     --bs-btn-hover-color: #fff !important;
    //     --bs-btn-active-bg: #004b78 !important;
    //     --bs-btn-active-border-color: #004b78 !important;
    // }
    // .btn-primary{
    //     border-color: #004b78 !important;
    //     --bs-btn-bg:#004b78 !important;
    //     --bs-btn-color: #fff !important;
    //     --bs-btn-hover-bg: #004b78 !important;
    //     --bs-btn-hover-color: #fff !important;
    //     --bs-btn-active-bg: #004b78 !important;
    //     --bs-btn-active-border-color: #004b78 !important;
    // }
    .add-button-text{
        padding-left: 0.5rem;
    }
}

.button-input-sub-node{
    z-index: 1;
}