@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts//Poppins/Poppins-Regular.ttf') format('woff2'),
       url('./assets/fonts//Poppins/Poppins-Regular.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, body, #root {
  height: 100%;
  font-family: "Poppins", sans-serif !important;
  font-size: calc(100vw * 0.00833)!important;
}

