.preview-panel{
  overflow-y: scroll;

  .refresh-button{
    display: flex;
    align-items: flex-end;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
}
.bot-message {
    background-color: #f5f5f5;
    color: #333;
    border-radius: 8px;
    padding: 4px 10px;
    margin-bottom: 10px;
    align-self: flex-start;
    max-width: 70%;
    margin-left: 0.75rem;
  }

  .user-message {
    background-color: #004b78;
    color: #fff;
    border-radius: 8px;
    padding: 4px 10px;
    margin-bottom: 10px;
    align-self: flex-end;
    max-width: 70%;
    margin-right: 0.75rem;
  }

  .user-button-group{
    margin-left: 3.5rem;
  }

  .robot-icon{
    color: #333;
  }

  .user-icon{
    color: #004b78;
    margin-left: 1rem;
  }

//   .btn-primary{
//     border-color: #004b78 !important;
//     --bs-btn-bg:#004b78 !important;
//     --bs-btn-color: #fff !important;
//     --bs-btn-hover-bg: #004b78 !important;
//     --bs-btn-hover-color: #fff !important;
//     --bs-btn-active-bg: #004b78 !important;
//     --bs-btn-active-border-color: #004b78 !important;
// }

// .btn-outline-primary{
//   border-color: #004b78 !important;
//   --bs-btn-color: #004b78 !important;
//   --bs-btn-hover-bg: #004b78 !important;
//   --bs-btn-hover-color: #fff !important;
//   --bs-btn-active-bg: #004b78 !important;
//   --bs-btn-active-border-color: #004b78 !important;
// }