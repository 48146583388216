.bot-create-content{
    .bot-create-top{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .bot-image{
        height: 25rem;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .bot-create-form{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 4rem;
    }
    
    .bot-create-text{
        text-align: center;
        margin-bottom: 3.5rem;
        font-weight: bold;
    }

    .white-text-button {
        color: white !important;
      }
    .label-text{
        font-weight: bold;
    }
}