.bot-list-view{
    .create-btn{
        text-align: end;
    }

    .bot-list-card{
        text-align: center;
        height: 9rem !important;
        margin-bottom: 3rem;
        cursor: pointer;

        .bot-card{
            vertical-align: middle;
        }
    }

    .dropdown-btn {
        padding: 0rem !important;
        font-size: 0.875rem;
        border: none;
        background-color: inherit;
      }

    .single-dropdown-toggle::after {
        content: none;
      }
    .white-text-button {
        color: white !important;
      }
}