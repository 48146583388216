@import '../LocalTheme.scss';
.top-layout{
    height: 7.5rem;
    border-bottom: solid 1px rgba(112, 112, 112, 0.08);
    background-color: #f4f4f4 !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    .flow-list-view-top{
        padding-top: 0.75rem;
        padding-bottom: 1rem;

        .control-hub{
          cursor: pointer;
        }

        .flowy-home{
          cursor: pointer;
        }

        .control-hub-navigator{
            margin-right: 0.75rem;
            margin-top: 1.25rem;
        }

        .flowy-navigator{
          margin-right: 0.75rem;
          margin-top: 1.75rem;
        }
    
        .logo-container {
            height: 1.8rem;
            img {
              height: 100%;
              max-height: 100%;
              max-width: 100%;
              margin-right: 0.5rem;
              margin-top: 0.4rem;
            }
          }

          .profile-icon{
            color: $primary !important;
          }

          .logout-text{
            margin-left: 0.5rem;
          }

          .flowy-text{
            font-weight: bold;
            padding-top: 0.5rem;
          }
          .flowy-sub-text{
            font-weight: bold;
            padding-bottom: 0.5rem;
          }
          .flex-start { 
            justify-content: flex-start; 
          }
          .flex-end { 
            justify-content: flex-end; 
          }
          .control-hub-text{
            margin-top: 0.75rem;
            margin-left: 0.35rem;
          }

          .dropdown-btn {
            padding: 0rem !important;
            font-size: 0.875rem;
            border: none;
            background-color: inherit;
          }
    
        .single-dropdown-toggle::after {
            content: none;
          }
    }

}

.bottom-layout{
    padding-bottom: 2rem;
    overflow-y: auto;
    margin-top: 7.5rem;
}